import React from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import HeroStock from "assets/images/hero.jpeg"

import {
  HeroSectionWrapper,
  HeroContentWrapper,
  HeroHeading,
  HeroContentLead,
  HeroContentCta,
  HeroImageWrapper,
  HeroImage,
} from "./styles"

const Hero = ({ fluid }) => (
  <HeroSectionWrapper id="hero">
    <HeroContentWrapper>
      <HeroHeading>
        <FormattedMessage id="hero.header" />
      </HeroHeading>
      <HeroContentLead>
        <FormattedMessage id="hero.lead" />
      </HeroContentLead>
      <Link to="/#shop">
        <HeroContentCta>
          <FormattedMessage id="hero.button" />
        </HeroContentCta>
      </Link>
    </HeroContentWrapper>
    <HeroImageWrapper>
      <HeroImage
        fluid={fluid}
        style={{ backgroundImage: `url(${HeroStock})` }}
      />
    </HeroImageWrapper>
  </HeroSectionWrapper>
)

export default injectIntl(Hero)
