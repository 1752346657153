import styled from "styled-components"
import DropdownMenu from "./DropdownMenu"
import { DEVICES } from "src/constants"

export const NavigationWrapper = styled.nav`
  align-items: center;
  background-color: var(--color-bg1);
  border-bottom: 1px solid var(--color-divider);
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9;
  @media ${DEVICES.laptop} {
    padding: 0 60px;
  }
  @media ${DEVICES.laptopL} {
    height: 90px;
  }
  @media ${DEVICES.desktop} {
    padding: 0 165px;
  }
`

export const NavigationLogo = styled.img`
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 28px;
  @media ${DEVICES.laptopL} {
    height: 30px;
  }
`

export const NavigationList = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
`

export const NavigationListItem = styled.li`
  color: var(--color-text-primary);
  display: none;
  font-size: 14px;
  font-weight: 600;
  margin-left: 32px;
  transition: all .2s ease-in;
  width: 1px;
  &:hover {
    color: var(--color-text-hover);
    cursor: pointer;
  }
  @media ${DEVICES.tablet} {
    display: block;
    width: 100%;
  }
  @media ${DEVICES.laptopL} {
    font-size: 16px;
    margin-left: 48px;
  }
`

export const DropdownInactive = styled(DropdownMenu)`
  width: 1px;
  opacity: 0;
  visibility: hidden;
`

export const DropdownActive = styled(DropdownMenu)`
  left: 0;
  opacity: 1;
  visibility: visible;
  top: 72px;
`
