import React from "react"
import Obfuscate from "react-obfuscate"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import EmailIcon from "assets/images/feather-icons/mail-light.svg"
import PhoneIcon from "assets/images/feather-icons/phone-light.svg"
import LocationIcon from "assets/images/feather-icons/location-light.svg"
import ClockIcon from "assets/images/feather-icons/clock.svg"

import {
  ContactSectionWrapper,
  ContactContentWrapper,
  ContactSectionTitle,
  ContactInfoSection,
  ContactInfoWrapper,
  ContactItem,
  ContactItemHeader,
  ContactItemIcon,
} from "./styles"

const ContactSection = () => (
  <ContactSectionWrapper id="contact">
    <ContactContentWrapper>
      <ContactSectionTitle>
        <FormattedMessage id="contact.header_main" />
      </ContactSectionTitle>
      <ContactInfoSection>
        <ContactInfoWrapper>
          <ContactItemHeader>
            <FormattedMessage id="contact.header_info" />
          </ContactItemHeader>
          <ContactItem>
            <ContactItemIcon src={EmailIcon} alt="envelope icon" />
            <span>
              <Obfuscate
                email="elpis@elpis.com.pl"
                headers={{
                  subject: "Nazwa projektu",
                }}
              >
                elpis@elpis.com.pl
              </Obfuscate>
            </span>
          </ContactItem>
          <ContactItem>
            <ContactItemIcon src={PhoneIcon} alt="phone icon" />
            <span>
              <Obfuscate tel="+48 91-311-84-43">+48 91-311-84-43</Obfuscate>
            </span>
          </ContactItem>
          <ContactItem>
            <ContactItemIcon src={PhoneIcon} alt="phone icon" />
            <span>
              <Obfuscate tel="+48 888-737-708">+48 888-737-708</Obfuscate>
            </span>
          </ContactItem>
        </ContactInfoWrapper>
        <ContactInfoWrapper>
          <ContactItemHeader>
            <FormattedMessage id="contact.header_address" />
          </ContactItemHeader>
          <ContactItem>
            <ContactItemIcon src={LocationIcon} alt="map pin icon" />
            <span>
              <FormattedMessage id="contact.company_address" />
            </span>
          </ContactItem>
          <ContactItem>
            <ContactItemIcon src={ClockIcon} alt="map pin icon" />
            <span>
              <FormattedMessage id="contact.open_hours" />
            </span>
          </ContactItem>
        </ContactInfoWrapper>
      </ContactInfoSection>
    </ContactContentWrapper>
  </ContactSectionWrapper>
)

export default injectIntl(ContactSection)
