import styled from "styled-components"
import { DEVICES } from "src/constants"

export const ProjectStepsWrapper = styled.section`
  background-color: var(--color-bg2);
  height: auto;
  padding: 40px 0 20px;
  @media ${DEVICES.tablet} {
    padding: 60px 0;
  }
  @media ${DEVICES.laptopL} {
    padding: 90px 0;
    text-align: left;
  }
`

export const ProjectStepsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  @media ${DEVICES.laptopL} {
    max-width: 1300px;
    overflow-x: hidden;
    text-align: left;
  }
`

export const ProjectStepsTitle = styled.h2`
  margin: 0 auto 36px;
  max-width: 327px;
  @media ${DEVICES.laptopL} {
    margin: 0 0 10px;
    max-width: 100%;
  }
`

export const ProjectStepsLead = styled.h3`
  font-weight: 400;
  margin: 0 auto;
  max-width: 80%;
  @media ${DEVICES.laptopL} {
    margin: 12px 0 0 0;
  }
`

export const StepItemsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  padding: 24px 24px 0;
  position: relative;
  @media ${DEVICES.tablet} {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      ". ."
      ". .";
    margin: 20px auto 0;
    max-width: 500px;
  }
  @media ${DEVICES.laptop} {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 900px;
    padding: 60px 0 0;
  }
  @media ${DEVICES.laptopL} {
    margin-top: 20px;
    max-width: 1300px;
  }
`

export const StepConnector = styled.hr`
  border: 0;
  border-bottom: 6px dotted var(--color-step-connector);
  bottom: 70px;
  display: none;
  left: 75px;
  opacity: 50%;
  position: absolute;
  width: calc(100vw - (165px * 2));
  z-index: 0;
  @media ${DEVICES.laptop} {
    display: block;
    max-width: 750px;
  }
  @media ${DEVICES.laptopL} {
    left: 175px;
    width: 650px;
  }
`
