import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"

import { getRedirectLanguage } from "src/helpers/redirectOnLocale"
import SEO from "components/seo"
import Navigation from "components/shared/Navigation"
import Hero from "components/homepage/Hero"
import Featured from "components/homepage/Featured"
import ProjectSteps from "components/homepage/ProjectSteps"
import ShopSection from "components/homepage/ShopSection"
import ContactSection from "components/homepage/ContactSection"
import Footer from "components/shared/Footer"
import CookieBanner from "components/homepage/CookieBanner"

const IndexPage = ({ data }) => {
  useEffect(() => {
    const urlLang = getRedirectLanguage()
    navigate(`/${urlLang}/`, { replace: true })
  }, [])

  return (
    <>
      <SEO title="Home" />
      <Navigation />
      <Hero fluid={data.hero.childImageSharp.fluid} />
      <Featured />
      <ProjectSteps />
      <ShopSection fluid={data.shop.childImageSharp.fluid} />
      <ContactSection />
      <Footer />
      <CookieBanner />
    </>
  )
}

export const query = graphql`
  query BufferImages {
    hero: file(name: { eq: "hero" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shop: file(name: { eq: "df-phone" }) {
      childImageSharp {
        fluid(maxHeight: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
