import styled from "styled-components"
import { DEVICES } from "src/constants"

export const FooterWrapper = styled.footer`
  align-items: center;
  background-color: var(--color-bg-dark);
  color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px;
  width: 100%;
  @media ${DEVICES.laptop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 60px;
  }
  @media ${DEVICES.desktop} {
    padding: 30px 165px;
  }
`

export const FooterItem = styled.span`
  font-size: 14px;
  line-height: 16px;
  opacity: 80%;
  padding: 5px 0;
  @media ${DEVICES.laptop} {
    padding: 0;
  }
`
