import React from "react"

import IconFeature from "./IconFeature"
import {
  IconSectionWrapper,
  FeatureTextHeader,
  FeatureTextParagraph,
} from "./styles"

const IconSectionItem = ({ icon, iconName, title, description, biggerIcon }) => (
  <>
    <IconSectionWrapper>
      <IconFeature biggerIcon={biggerIcon}>
        <img src={icon} alt={iconName} />
      </IconFeature>
      <div>
        <FeatureTextHeader>{title}</FeatureTextHeader>
        <FeatureTextParagraph>{description}</FeatureTextParagraph>
      </div>
    </IconSectionWrapper>
  </>
)

export default IconSectionItem
