import React from "react"

import {
  StepItemWrapper,
  StepItemNumber,
  StepItemTitle,
  StepItemDescription,
} from "./styles"

const StepItem = ({ step, title, description }) => (
  <StepItemWrapper>
    <StepItemNumber>{step}</StepItemNumber>
    <StepItemTitle>{title}</StepItemTitle>
    <StepItemDescription>{description}</StepItemDescription>
  </StepItemWrapper>
)

export default StepItem
