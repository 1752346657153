import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import IconSectionItem from "./IconSectionItem"

import CircuitIcon from "assets/images/adobe/circuit.svg"
import CogIcon from "assets/images/adobe/cog.svg"
import CpuIcon from "assets/images/adobe/cpu.svg"
import CertificateLogo from "assets/images/ce-logo.svg"

import {
  FeaturedSectionWrapper,
  FeaturedContentWrapper,
  FeaturedTitle,
  IconSectionWrapper,
} from "./styles"

const Featured = () => (
  <FeaturedSectionWrapper id="featured">
    <FeaturedContentWrapper>
      <FeaturedTitle>
        <FormattedMessage id="featured.header" />
      </FeaturedTitle>
      <IconSectionWrapper>
        <IconSectionItem
          biggerIcon
          icon={CircuitIcon}
          iconName="Send"
          title={<FormattedMessage id="featured.icon1.title" />}
          description={<FormattedMessage id="featured.icon1.description" />}
        />
        <IconSectionItem
          icon={CertificateLogo}
          iconName="Send"
          title={<FormattedMessage id="featured.icon2.title" />}
          description={<FormattedMessage id="featured.icon2.description" />}
        />
        <IconSectionItem
          biggerIcon
          icon={CogIcon}
          iconName="Send"
          title={<FormattedMessage id="featured.icon3.title" />}
          description={<FormattedMessage id="featured.icon3.description" />}
        />
        <IconSectionItem
          biggerIcon
          icon={CpuIcon}
          iconName="Send"
          title={<FormattedMessage id="featured.icon4.title" />}
          description={<FormattedMessage id="featured.icon4.description" />}
        />
      </IconSectionWrapper>
    </FeaturedContentWrapper>
  </FeaturedSectionWrapper>
)

export default injectIntl(Featured)
