import React from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import { FooterWrapper, FooterItem } from "./styles"

const Footer = () => (
  <FooterWrapper>
    <div>
      <FooterItem className="m-right">
        <Link to="/">
          <FormattedMessage id="footer.main" />
        </Link>
      </FooterItem>
      <FooterItem>
        <Link to="/rodo">
          <FormattedMessage id="footer.policy" />
        </Link>
      </FooterItem>
    </div>
    <FooterItem>© 2020 Elpis. All rights reserved</FooterItem>
  </FooterWrapper>
)

export default injectIntl(Footer)
