import styled from "styled-components"
import { DEVICES } from "src/constants"

export const IconWrapper = styled.div`
  align-items: center;
  background-color: var(--color-bg2);
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 72px;
  justify-content: center;
  width: 72px;
  @media ${DEVICES.laptopL} {
    margin-right: 40px;
  }
  img {
    height: ${props => props.biggerIcon ? `45px` : `25px` };
    mix-blend-mode: multiply;
    opacity: .5;
  }
`
