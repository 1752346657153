import styled from "styled-components"
import Image from "gatsby-image"

import Button from "components/common/Button"
import { DEVICES } from "src/constants"

export const HeroSectionWrapper = styled.section`
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column-reverse;
  min-height: calc(100vh - 72px);
  margin-top: 72px;
  width: 100%;
  @media ${DEVICES.laptop} {
    flex-direction: row;
  }
  @media ${DEVICES.desktop} {
    margin-top: 90px;
  }
`

export const HeroContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: auto;
  text-align: center;
  @media ${DEVICES.laptop} {
    align-items: flex-start;
    height: auto;
    justify-content: center;
    margin: 0 0 0 60px;
    text-align: left;
  }
  @media ${DEVICES.laptopL} {
    width: 50vw;
  }
  @media ${DEVICES.desktop} {
    margin: 0 0 0 165px;
  }
`

export const HeroHeading = styled.h1`
  margin-top: 20px;
  max-width: 90%;
  @media ${DEVICES.tablet} {
    margin-top: 40px;
    max-width: 60%;
  }
  @media ${DEVICES.laptop} {
    max-width: 70%;
  }
  @media ${DEVICES.laptopL} {
    max-width: 500px;
  }
`

export const HeroContentLead = styled.p`
  margin: 28px 0;
  width: 90%;
  @media ${DEVICES.tablet} {
    width: 70%;
  }
  @media ${DEVICES.laptop} {
    margin-bottom: 32px;
    width: 80%;
  }
  @media ${DEVICES.laptopL} {
    margin-bottom: 48px;
    width: 700px;
  }
`

export const HeroContentCta = styled(Button)`
  background-color: var(--color-bttn1-bg);
  color: var(--color-text-light);
  height: 56px;
  margin-bottom: 40px;
  width: 216px;
  transition: all 0.2s ease-in;
  &:hover {
    background-color: var(--color-bttn1-bg-hover);
  }
`

export const HeroImageWrapper = styled.div`
  background-color: var(--color-bg-secondary);
  height: 275px;
  margin: 0;
  width: 100%;
  @media ${DEVICES.tablet} {
    height: 50vh;
  }
  @media ${DEVICES.laptop} {
    height: calc(100vh - 72px);
  }
  @media ${DEVICES.laptopL} {
    width: 50vw;
  }
`

export const HeroImage = styled(Image)`
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  height: 100%;
`
