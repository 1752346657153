import styled from "styled-components"
import Image from "gatsby-image"
import { DEVICES } from "src/constants"

export const ShopSectionWrapper = styled.section`
  @media ${DEVICES.laptopL} {
    align-items: center;
    background-color: var(--color-bg1);
    display: flex;
    height: 750px;
    justify-content: center;
  }
`

export const ShopContentWrapper = styled.div`
  align-items: center;
  background-color: var(--color-bg-accent);
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  padding: 40px 0;
  @media ${DEVICES.tablet} {
    padding: 60px 0;
  }
  @media ${DEVICES.laptopL} {
    border-radius: 16px;
    flex-direction: row;
    height: 450px;
    padding: 48px;
    max-width: 1100px;
  }
`

export const ShopPromoWrapper = styled.article`
  text-align: center;
  width: 90%;
  @media ${DEVICES.laptopL} {
    text-align: left;
  }
`

export const ShopPromoTitle = styled.h2`
  margin-top: 24px;
  @media ${DEVICES.tablet} {
    margin-top: 40px;
  }
  @media ${DEVICES.laptopL} {
    font-size: 34px;
    margin: 0;
  }
`

export const ShopPromoText = styled.p`
  margin: 14px 0;
  @media ${DEVICES.tablet} {
    margin: 20px auto;
    max-width: 80%;
  }
  @media ${DEVICES.laptop} {
    width: 60%;
  }
  @media ${DEVICES.laptopL} {
    margin: 20px 0 16px;
    width: 80%;
  }
`

export const ImageWrapper = styled.div`
  @media ${DEVICES.laptopL} {
    margin-right: 80px;
  }
`

export const ShopPromoImage = styled(Image)`
  width: 250px;
  @media ${DEVICES.tablet} {
    width: 300px;
  }
`
