import styled from "styled-components"

export const DropdownWrapper = styled.nav`
  background-color: var(--color-bg1);
  border-bottom: 1px solid var(--color-divider);
  padding: 10px 20px;
  position: absolute;
  text-align: center;
  width: 100%;
`

export const DropdownItem = styled.li`
  font-weight: 600;
  margin: 10px 0;
`
