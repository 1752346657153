import styled from "styled-components"

const Button = styled.button`
  background: var(--color-bttn2-bg);
  border: none;
  border-radius: 8px;
  color: var(--color-bttn2-text);
  flex-shrink: 0;
  font-size: 18px;
  font-weight: bold;
  height: 62px;
  width: 45%;
  img {
    padding-right: 10px;
  }
`

export default Button
