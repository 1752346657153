import styled from "styled-components"
import { DEVICES } from "src/constants"

export const IconSectionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  max-width: 300px;
  @media ${DEVICES.tablet} {
    max-width: 340px;
  }
  @media ${DEVICES.laptopL} {
    flex-direction: row;
    max-width: 95%;
    text-align: left;
  }
`

export const FeatureTextHeader = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 20px;
`

export const FeatureTextParagraph = styled.p`
  margin-top: 12px;
  @media ${DEVICES.tablet} {
    margin: 12px auto 0;
    max-width: 280px;
  }
  @media ${DEVICES.laptop} {
    max-width: 300px;
  }
  @media ${DEVICES.laptopL} {
    max-width: 100%;
  }
`
