import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import LinkToPage from "components/common/LinkToPage"
import ArrowLabel from "assets/images/feather-icons/arrow-black.svg"
import PhoneMockup from "assets/images/df-phone.png"
import {
  ShopSectionWrapper,
  ShopContentWrapper,
  ShopPromoWrapper,
  ShopPromoTitle,
  ShopPromoText,
  ImageWrapper,
  ShopPromoImage,
} from "./styles"

const ShopSection = ({ fluid }) => (
  <ShopSectionWrapper id="shop">
    <ShopContentWrapper>
      <ShopPromoWrapper>
        <ShopPromoTitle>
          <FormattedMessage id="shop.title" />
        </ShopPromoTitle>
        <ShopPromoText>
          <FormattedMessage id="shop.description.para1" />
        </ShopPromoText>
        <ShopPromoText>
          <FormattedMessage id="shop.description.para2" />
        </ShopPromoText>
        <LinkToPage>
          <a href="https://devicefab.com/" target="blank" rel="noopener">
            <FormattedMessage id="shop.cta" />
            <img src={ArrowLabel} alt="arrow pointing right" />
          </a>
        </LinkToPage>
      </ShopPromoWrapper>
      <ImageWrapper>
        <ShopPromoImage
          fluid={fluid}
          src={PhoneMockup}
          alt="phone showing shop page"
        />
      </ImageWrapper>
    </ShopContentWrapper>
  </ShopSectionWrapper>
)

export default injectIntl(ShopSection)
