import styled from "styled-components"
import { DEVICES } from "src/constants"

export const StepItemWrapper = styled.div`
  background-color: var(--color-bg-secondary);
  margin-bottom: 20px;
  text-align: center;
  z-index: 1;
  @media ${DEVICES.tablet} {
    margin: auto;
  }
`

export const StepItemNumber = styled.span`
  display: block;
  color: var(--color-step-num);
  font-size: 80px;
  font-weight: 600;
  margin-bottom: -42px;
  @media ${DEVICES.laptopL} {
    font-size: 96px;
    font-weight: 700;
    margin-bottom: -46px;
  }
`

export const StepItemTitle = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 14px;
  @media ${DEVICES.laptopL} {
    font-size: 22px;
  }
`

export const StepItemDescription = styled.p`
  width: 200px;
  line-height: 25px;
  @media ${DEVICES.laptopL} {
    line-height: 26px;
    width: 230px;
  }
`
