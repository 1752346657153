import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import StepItem from "./StepItem"
import {
  ProjectStepsWrapper,
  ProjectStepsContentWrapper,
  ProjectStepsTitle,
  ProjectStepsLead,
  StepItemsWrapper,
  StepConnector,
} from "./styles"

const ProjectSteps = () => (
  <ProjectStepsWrapper id="steps">
    <ProjectStepsContentWrapper>
      <ProjectStepsTitle>
        <FormattedMessage id="process.header" />
      </ProjectStepsTitle>
      <ProjectStepsLead>
        <FormattedMessage id="process.description" />
      </ProjectStepsLead>
      <StepItemsWrapper>
        <StepItem
          step="1"
          title={<FormattedMessage id="process.step1.title" />}
          description={<FormattedMessage id="process.step1.description" />}
        />
        <StepItem
          step="2"
          title={<FormattedMessage id="process.step2.title" />}
          description={<FormattedMessage id="process.step2.description" />}
        />
        <StepItem
          step="3"
          title={<FormattedMessage id="process.step3.title" />}
          description={<FormattedMessage id="process.step3.description" />}
        />
        <StepItem
          step="4"
          title={<FormattedMessage id="process.step4.title" />}
          description={<FormattedMessage id="process.step4.description" />}
        />
        <StepConnector />
      </StepItemsWrapper>
    </ProjectStepsContentWrapper>
  </ProjectStepsWrapper>
)

export default injectIntl(ProjectSteps)
