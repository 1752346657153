import React, { useState } from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Burger from "@animated-burgers/burger-squeeze"
import "@animated-burgers/burger-squeeze/dist/styles.css"

import LogoHorizontal from "assets/images/logo-fullcolor.svg"
import "./burger.css"
import {
  NavigationWrapper,
  NavigationLogo,
  NavigationList,
  NavigationListItem,
  DropdownActive,
  DropdownInactive,
} from "./styles"

const Navigation = () => {
  const [isActive, setIsActive] = useState(false)
  const onClick = () => {
    setIsActive(!isActive)
  }
  const Dropdown = isActive ? DropdownActive : DropdownInactive
  const CheckIfOpen = isActive ? true : false

  return (
    <NavigationWrapper>
      <Link to="/#hero">
        <NavigationLogo src={LogoHorizontal} alt="company logo horizontal" />
      </Link>
      <NavigationList>
      <NavigationListItem>
          <Link to="/#hero" >
            <FormattedMessage id="navigation.home" />
          </Link>
        </NavigationListItem>
        <NavigationListItem>
          <Link to="/#featured" >
            <FormattedMessage id="navigation.service" />
          </Link>
        </NavigationListItem>
        <NavigationListItem>
          <Link to="/#steps">
            <FormattedMessage id="navigation.process" />
          </Link>
        </NavigationListItem>
        <NavigationListItem>
          <Link to="/#shop">
            <FormattedMessage id="navigation.shop" />
          </Link>
        </NavigationListItem>
        <NavigationListItem>
          <Link to="/#contact">
            <FormattedMessage id="navigation.contact" />
          </Link>
        </NavigationListItem>
      </NavigationList>
      <Burger
        className="burger burger-squeeze menu-trigger"
        onClick={onClick}
        isOpen={CheckIfOpen}
      />
      <Dropdown onClick={onClick}/>
    </NavigationWrapper>
  )
}

export default injectIntl(Navigation)
