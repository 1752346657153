import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import { COLORS } from "src/constants"

const CookieBanner = () => (
  <CookieConsent
    location="bottom"
    buttonText={<FormattedMessage id="cookies.cta" />}
    cookieName="elpisCookie"
    style={{
      alignItems: "center",
      background: COLORS.background_black,
      color: COLORS.text_light,
      display: "flex",
      height: "auto",
      padding: "10px",
    }}
    buttonStyle={{
      background: COLORS.button_primary_bg,
      borderRadius: "6px",
      color: COLORS.text_light,
      fontSize: "15px",
      fontWeight: "600",
      height: "50px",
      width: "120px",
    }}
    expires={150}
  >
    <FormattedMessage id="cookies.banner_text" />
    <Link to="/rodo">
      <span style={{ display: "block", fontSize: "12px" }}>
        {" "}
        <FormattedMessage id="cookies.additional_text" />
      </span>
    </Link>
  </CookieConsent>
)

export default injectIntl(CookieBanner)
