import styled from "styled-components"
import { DEVICES } from "src/constants"

export const ContactSectionWrapper = styled.section`
  align-items: center;
  background-color: var(--color-bg-dark);
  color: var(--color-text-light);
  min-height: calc(100vh - 160px);
  padding: 40px 0 0;
  @media ${DEVICES.tablet} {
    min-height: 100%;
    height: auto;
    padding: 60px 0;
  }
`

export const ContactContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media ${DEVICES.laptopL} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    max-width: 1300px;
  }
  @media ${DEVICES.laptopL} {
    min-height: 300px;
  }
`

export const ContactSectionTitle = styled.h2`
  display: block;
  margin-bottom: 8vh;
  @media ${DEVICES.laptop} {
    margin-bottom: 60px;
  }
  @media ${DEVICES.laptopL} {
    margin-bottom: 0;
  }
`

export const ContactInfoSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  min-height: 35vh;
  @media ${DEVICES.tablet} {
    align-items: flex-start;
    flex-direction: row;
    min-height: auto;
    width: 500px;
  }
  @media ${DEVICES.laptopL} {
    align-items: flex-start;
    margin: 0;
    width: 600px;
  }
`

export const ContactInfoWrapper = styled.div`
  margin-bottom: 30px;
  @media ${DEVICES.laptopL} {
    margin: 0;
  }
  @media ${DEVICES.laptopL} {
    text-align: left;
  }
`

export const ContactItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto 8px;
  max-width: 175px;
  span {
    transition: all .2s ease-in;
  }
  span:hover {
    color: var(--color-text-hover);
    cursor: pointer;
  }
  @media ${DEVICES.tablet} {
    max-width: 180px;
  }
  @media ${DEVICES.laptopL} {
    justify-content: flex-start;
    margin-left: 0;
  }
`

export const ContactItemHeader = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 14px;
`

export const ContactItemIcon = styled.img`
  margin-right: 10px;
  width: 18px;
`
