export const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "en"
    }

    const lang =
      navigator && navigator.language && navigator.language.split("-")[0]

    if (!lang) return "en"

    switch (lang) {
      case "pl":
        return "pl"
      case "de":
        return "de"
      default:
        return "en"
    }
  }
