import React from "react"
import { DropdownWrapper, DropdownItem } from "./styles"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const DropdownMenu = ({ className, onClick }) => (
  <DropdownWrapper className={className}>
    <ul>
      <DropdownItem onClick={onClick}>
        <Link to="/#hero">
          <FormattedMessage id="navigation.home" />
        </Link>
      </DropdownItem>
      <DropdownItem onClick={onClick}>
        <Link to="/#featured">
          <FormattedMessage id="navigation.service" />
        </Link>
      </DropdownItem>
      <DropdownItem onClick={onClick}>
        <Link to="/#steps">
          <FormattedMessage id="navigation.process" />
        </Link>
      </DropdownItem>
      <DropdownItem onClick={onClick}>
        <Link to="/#shop">
          <FormattedMessage id="navigation.shop" />
        </Link>
      </DropdownItem>
      <DropdownItem onClick={onClick}>
        <Link to="/#contact">
          <FormattedMessage id="navigation.contact" />
        </Link>
      </DropdownItem>
    </ul>
  </DropdownWrapper>
)

export default injectIntl(DropdownMenu)
