import styled from "styled-components"
import { DEVICES } from "src/constants"

export const FeaturedSectionWrapper = styled.section`
  height: auto;
  padding: 40px 0;
  @media ${DEVICES.tablet} {
    padding: 60px 0;
  }
  @media ${DEVICES.laptopL} {
    padding: 100px 60px;
  }
`

export const FeaturedContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${DEVICES.laptopL} {
    margin: 0 auto;
    max-width: 1300px;
  }
`

export const FeaturedTitle = styled.h2`
  margin: auto 48px 0;
  text-align: center;
  @media ${DEVICES.tablet} {
    margin-bottom: 30px;
  }
  @media ${DEVICES.laptopL} {
    align-self: flex-start;
    margin: 0;
    text-align: left;
  }
`

export const IconSectionWrapper = styled.div`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 30px;
  grid-template-areas:
    "."
    ".";
  text-align: center;
  @media ${DEVICES.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 48px;
    grid-template-areas:
      ". ."
      ". .";
  }
  @media ${DEVICES.laptopL} {
    gap: 0 48px;
  }
`
